.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.title {
  width: 116px;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;

}

.subtitle {
  width: 773px;
  margin-bottom: 0;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  margin-top: 10px;
  color: #333333;
}

.datagrid__header .MuiTableCell-head {
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #D1D3D4;
  text-transform: uppercase;
  height: 50px;
}

.data_retrieval_method_container {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.data_retrieval_method {
  align-items: center;
  margin: 10px;
  padding: 4px 10px;
  background: rgba(99, 59, 235, 0.05);
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.data_retrieval_method_name {
  font-family: Nunito sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: blue !important;
}

.wallet_balance_container {
    display: flex;
    flex-direction: column-reverse;
    width: auto;
}

.wallet_balance {
  background: rgba(99, 59, 235, 0.05);
  border-radius: 10px;
  color: blue !important;
  margin: 5px
}

/*!!! FIx for menu styles, broken by internal packages styles conflict*/
/*!Important It can be fixed in the future by updating packages*/
/* Related packages ra-editable-grid, react-admin, material-ui */

/* Menu bar fix */
.MenuContainer .MuiButtonBase-root {
  justify-content: flex-start;
  display: flex;
  text-align: left;
  align-items: center;
  position: relative;
  width: auto;
  padding: 8px 16px!important;
}

/* Dropdown fix */
.MuiList-root.MuiMenu-list .MuiButtonBase-root {
  white-space: nowrap;
  padding: 6px 16px;
  display: flex;
  position: relative;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
}

/* Autocomplete input */
.ra-ref-input {
  z-index: 10;
}

.ra-ref-input .MuiButtonBase-root {
  display: flex!important;
  justify-content: flex-start!important;
  padding: 0 5px;
}

/* Header */
.skip-nav-button.MuiButtonBase-root {
  position: fixed;
}

header.MuiPaper-root .MuiButtonBase-root.MuiIconButton-root {
  margin-left: 0.2em;
  margin-right: 0.2em;
  padding: 12px;
}
